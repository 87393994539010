import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Blockquote from "../../../../../components/blockquote"
import Poem from "../../../../../components/poem"
import ComicStrip from "../../../../../components/comic-strip"

const Page = () => {
  const { getAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const answer = getAnswer({
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
    taskId: "gedicht-in-emojis",
  })

  const consolidatedAnswers = answer
    ? Object.values(answer)
        .reduce((acc, curr, i) => {
          const consolidatedIndex = Math.floor(i / 2)
          if (!acc[consolidatedIndex]) {
            acc[consolidatedIndex] = []
          }
          acc[consolidatedIndex].push(curr)
          return acc
        }, [])
        .map((set) => set.join(" "))
        .filter((set) => !!set)
    : []

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/argumente-sammeln" />
      }
    >
      <Seo title="Das Gedicht in Emojis" />
      <Stack>
        <Heading as="h2" level={2}>
          Das Gedicht in Emojis
        </Heading>
        <Paragraph>Deine Emoji-Bildergeschichte zum Gedicht:</Paragraph>
        {answer && (
          <ComicStrip
            contents={consolidatedAnswers}
            textBoxes={["Der Nordost…", "und ich so…", "aber…", "und dann…"]}
          />
        )}
        <Paragraph>
          Das Gedicht beginnt mit einer sehnsuchtsvollen Erinnerung an eine
          durchaus positiv erlebte Landschaft. Verheißungsvoll bläst der
          Nordwind durch die Segel der Schiffe. Die Freiheit und Weite des
          offenen Meers sind fast spürbar. In freundschaftlicher Personifikation
          lässt das lyrische Ich die »schöne Garonne« und »die Gärten von
          Bourdeaux« grüßen. Auch die 2. Strophe des Gedichts versammelt
          Eindrücke und Landschaftsbeschreibungen, die dem lyrischen Ich in
          Erinnerung geblieben sind. Nicht die belebte Großstadt Bordeaux,
          sondern ländliche Szenen und Landschaften kommen ihm ins Gedächtnis.
          Doch mit der 3. Strophe kippt die Stimmung. Das lyrische Ich zieht
          sich zurück.
        </Paragraph>
        <Blockquote>
          <Poem>
            <p>Es reiche aber, </p>
            <p>Des dunkeln Lichtes voll, </p>
            <p>Mir einer den duftenden Becher,</p>
            <p>Damit ich ruhen möge; denn süß </p>
            <p>Wär’ unter Schatten der Schlummer. </p>
            <p>Nicht ist es gut, </p>
            <p>Seellos von sterblichen </p>
            <p>Gedanken zu seyn. Doch gut </p>
            <p>Ist ein Gespräch und zu sagen </p>
            <p>Des Herzens Meinung, zu hören viel </p>
            <p>Von Tagen der Lieb’, </p>
            <p>Und Thaten, welche geschehen.</p>
          </Poem>
        </Blockquote>
        <Paragraph>
          Als hätte sich daraufhin der Schlummer über das lyrische Ich gelegt,
          folgen im Anschluss nur noch allgemeine Bemerkungen, die nicht mehr
          aus einer individuellen Beobachter-Situation geschildert werden:
        </Paragraph>
        <Blockquote>
          <Poem>
            <p>Nicht ist es gut, </p>
            <p>Seellos von sterblichen </p>
            <p>Gedanken zu seyn. Doch gut </p>
            <p>Ist ein Gespräch und zu sagen </p>
            <p>Des Herzens Meinung, zu hören viel </p>
            <p>Von Tagen der Lieb’, </p>
            <p>Und Thaten, welche geschehen.</p>
          </Poem>
        </Blockquote>
        <Paragraph>
          Die Stimmung kippt genau in der Mitte des Gedichts, in Vers 29. Der
          Wunsch nach Ruhe und Schlummer könnte somit auch als Todessehnsucht
          verstanden werden. Allein und ohne Freunde ist das lyrische Ich nun.
          Ebenso entbehren auch die Seefahrer die Feiertage und Festlichkeiten
          der Stadt. Auf See sammeln sie nicht nur das »Schöne der Erd’«, sie
          müssen auch mit Kriegen und Verlusten rechnen.
        </Paragraph>
        <Paragraph>
          Zuletzt endet das Gedicht jedoch mit einer Naturbeschreibung voller
          Sehnsucht und Schönheit:
        </Paragraph>
        <Blockquote>
          <Poem>
            <p>Und zusammen mit der prächt'gen </p>
            <p>Garonne meerbreit </p>
            <p>Ausgehet der Strom. Es nehmet aber</p>
            <p>Und giebt Gedächtniß die See, </p>
            <p>Und die Lieb’ auch heftet fleißig die Augen, </p>
            <p>Was bleibet aber, stiften die Dichter.</p>
          </Poem>
        </Blockquote>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
